// import { Component, h, Host, State, Prop } from '@stencil/core';
// import { RouterHistory, injectHistory } from '@stencil/router';
import React from 'react';
import { ServiceAuth } from '../../services/service-auth';
import appState from '../../state/AppStateContainer';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NotificationManager } from 'react-notifications';
import { Link, withRouter, Redirect } from 'react-router-dom';
import BtButton from '../bt-button/bt-button';

import './app-login.css';
import AppLabel from '../app-label/app-label';
import Logo from '../../components/svgs/logo';
import SignInWithMicrosoft from '../../assets/svg/sign-in-with-microsoft.svg';

import { ServiceUser } from '../../services/service-user';

import styles from './app-login.module.scss';
import appEnum from '../../util/appEnum';
export class AppLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      btnLoginLoaderShow: false,
      errorShow: false,
      error: "",
      isM3: false,
      isBrixly: false,
    };
    this.elEmail = React.createRef();
    this.elPassword = React.createRef();
  }
  logo;
  modalForgotPassword = this.props.modalForgotPassword;
  forgotPassword;

  // logo2: SVGElement;

  componentWillLoad() {
   
    if (ServiceAuth.isLoggedIn) {
      
    
      this.history.replace('/');
    }
  }

  componentDidMount() {
  
    this.bind();

    // anime({
    //     targets: this.logo.querySelector('path'),
    //     strokeDashoffset: [anime.setDashoffset, 0],
    //     easing: 'easeInSine',
    //     duration: 3000,
    //     delay: function(_el, i) { return i * 250 },
    // });

    // anime({
    //     targets: this.logo.querySelector('path'),
    //     easing: 'easeInOutSine',
    //     duration: 450,
    //     delay: 0,
    //     fill: ['rgba(0,0,0,0)', '#001659']
    // });
  }

  componentDidUnload() {
    this.unbind();
  }

  bind() {
    this.unbind();
    document.addEventListener('keydown', this.documentKeydownCallback);
  }

  unbind() {
    document.removeEventListener('keydown', this.documentKeydownCallback);
  }

  documentKeydownCallback = e => {
    if (e.keyCode == 13) {
      this.login();
    }
  };


  setTokenAndRedirect = async data => {
    const urlToRedirect = appState.loadString('urlToRedirect');
    if (urlToRedirect) {
      const paths = urlToRedirect.split('/');
      const orgExist = data?.organizationData?.find(o => o.slug == paths[1]);
      const isSuperAdmin = data?.userData?.role == appEnum.Role.SuperAdmin;

      localStorage.removeItem('urlToRedirect');
      if (orgExist || isSuperAdmin) {
        await appState.setToken(data);
        window.location.href = urlToRedirect;
        return;
      }
    }
    await appState.setToken(data, true);
  };


   login = async () => {
    //setSubmitting(true);
    try {
      if (this.state.btnLoginLoaderShow) {
        return;
      }
      var email = this.elEmail.current.value;
      var password = this.elPassword.current.value;

      this.setState({ btnLoginLoaderShow: true, errorShow: false });
      let data = await ServiceAuth.login(email, password);
      
      if(data){
      let token = data.userData;
      this.setState({ btnLoginLoaderShow: false, errorShow: false });

      // NotificationManager.success(
      //   this.props.intl.formatMessage({
      //     id: "global.loginSuccessful",
      //     defaultMessage: "Login Successful"
      //   }),
      //   this.props.intl.formatMessage({
      //     id: "global.success",
      //     defaultMessage: "Success"
      //   })
      // );
      if (_.get(this, 'props.location.state.from')) {
        token = { ...token, route: _.get(this, 'props.location.state.from') };
        this.props.location.state.from = undefined;
      }
      
      appState.setState({loader: true});
      await this.setTokenAndRedirect(data);

      const [, slugFromUrl] = window.location.pathname.split("/");
      const hasAccessToOrgSlug = await ServiceUser.hasAccessToSlug(token.id, slugFromUrl);

      if (hasAccessToOrgSlug) {
        window.location.reload();
      } else {
        const { slug } = await appState.getCurrentOrgUrl();
        window.location.href = `${slug}/dashboard`;
      }
    }
    } catch (e) {
      console.log(e);
      const isM3 = email.trim().endsWith("m3bygg.se") && !window.location.hostname.startsWith("m3bygg.");
      const isBrixly = email.trim().endsWith("brixly.se") && !window.location.hostname.startsWith("brixly.");
      this.setState({ btnLoginLoaderShow: false, errorShow: true, error: e.response?.data?.error, isM3, isBrixly });

      // NotificationManager.error(
      //   this.props.intl.formatMessage({
      //     id: _.get(e, "response.data.error", "error.unexpectedError"),
      //     defaultMessage: "An unexpected error has occurred"
      //   }),
      //   this.props.intl.formatMessage({
      //     id: "global.error",
      //     defaultMessage: "Error"
      //   })
      // );
    }
  }

  async signInWithMicrosoft() {
    const url = await ServiceAuth.getMicrosoftSignInUrl();
    window.location.href = url;
  }

  // login() {
  //     if (this.state.btnLoginLoaderShow) {
  //         return;
  //     }

  //     this.setState({btnLoginLoaderShow: true, errorShow: false});
  //     ServiceAuth.login(this.elEmail.elInput.value, this.elPassword.elInput.value).then(() => {
  //         this.history.push('/dashboard');
  //     }).catch(() => {
  //         this.setState({btnLoginLoaderShow: false, errorShow: true});
  //     });
  // }

  render() {
    return (
      <div className="app-login bt-w60">
   
        <Logo size="194px" type="logoWithTitle" />
        <div className="input-wrapper custom-input-wrapper">
          <AppLabel class="ar-label" headline="E-post">
            <input
              placeholder={this.props.intl.formatMessage({
                id: 'global.E-Post',
                defaultMessage: 'E-Post',
              })}
              type="email"
              className="addSup-orgNo ar-text-field"
              name="email"
              ref={this.elEmail}
              value={this.props.email}
              onChange={(e) => this.props.setEmail(e.target.value)}
            />
          </AppLabel>
          <AppLabel class="ar-label" headline="Lösenord">
            <input
              placeholder={this.props.intl.formatMessage({
                id: 'global.lösenord',
                defaultMessage: 'Lösenord',
              })}
              className="addSup-orgNo ar-text-field"
              name="password"
              type="password"
              ref={this.elPassword}
            />
          </AppLabel>
              <div className="forgot-password-wrapper">
                <a className="forgot-password small" href="#" onClick={this.props.showFogotPassword}>
                  <FormattedMessage id="login.glömtLösenordet" defaultMessage="Glömt lösenordet?" />
                </a>
              </div>
          <div className="btn-login-wrapper">
            <BtButton
              loaderShow={this.state.btnLoginLoaderShow}
              onClick={()=> this.login()}
            >
              <FormattedMessage id="login.LoggaIn" defaultMessage="Logga in" />
            </BtButton>
          </div>
          <div className={styles.orContainer}>
            <span>
              Eller
            </span>
            <img src={SignInWithMicrosoft} onClick={this.signInWithMicrosoft.bind(this)} style={{cursor:"pointer"}}></img>
          </div>
        </div>
        <div className={`error ${this.state.errorShow ? 'show' : ''}`}>
          <p>
            {(this.state.error ?? "").startsWith("error.azureAdOnly") && (
              <FormattedMessage
              id="login.felaktivtAnvändarnamnEllerLösenord"
              defaultMessage="Endast Microsoftinloggning tillåten för detta konto"
              />
            )}
            {!(this.state.error ?? "").startsWith("error.azureAdOnly") && (
              <div>
                <FormattedMessage
                  id="login.felaktivtAnvändarnamnEllerLösenord"
                  defaultMessage="Felaktigt användarnamn eller lösenord"
                />
                {this.state.isBrixly && (
                  <p>Är du på rätt ställe? Brixlys Accuratorkonto finns på <a href="https://brixly.accurator.se/">https://brixly.accurator.se/.</a></p>
                )}
                {this.state.isM3 && (
                  <p>Är du på rätt ställe? M3Byggs Accuratorkonto finns på <a href="https://m3bygg.accurator.se/">https://m3bygg.accurator.se/.</a></p>
                )}
              </div>
            )}
          </p>
        </div>
      </div>
    );
  }
}
export default withRouter(injectIntl(AppLogin));
