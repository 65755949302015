// import { Component, h, Host, State, Prop } from '@stencil/core';
// import { RouterHistory, injectHistory } from '@stencil/router';
import React from 'react';
import { ServiceAuth } from '../../services/service-auth';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import BtButton from '../bt-button/bt-button';
import AppLabel from '../app-label/app-label';

import '../app-login/app-login.css';
import Logo from '../../components/svgs/logo';
import { SlugContext } from '../../App';
import { showErrorNotification, showSuccessNotification } from '../../util';

export class AppChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changePassword: false,
      btnLoginLoaderShow: false,
      errorMessage: '',
      errorShow: false,
      email: '',
    };
    this.confirmPassword = React.createRef();
    this.newPassword = React.createRef();
    this.oldPassword = React.createRef();
  }
  logo;

  // logo2: SVGElement;

  componentWillLoad() {
    if (ServiceAuth.isLoggedIn) {
      this.history.replace('/dashboard');
    }
  }

  async componentDidMount() {
    const changePassword = _.get(this, 'props.changePassword');

    this.setState({ changePassword });
    if (!changePassword) await this.getUserData();
  }

  getUserData = async () => {
    let userData;
    const verificationcode = _.get(this, 'props.match.params.verificationcode');

    if (verificationcode) {
      userData = await ServiceAuth.getUserFromVerificationCode(verificationcode);
    }
    if (userData) {
      this.setState({ email: userData.email });
      return;
    }
    this.props.history.replace('/error');
  };

  btnSubmitPasswordClickCallback = () => {
    this.submitRequest();
  };

  regexChecker(value) {
    const check = value.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/) || [
      '',
    ];

    return check[0] === value;
  }

  async submitRequest() {
    //setSubmitting(true);
    try {
      if (this.state.btnLoginLoaderShow) {
        return;
      }

      const newPassword = this.newPassword.current.value;
      const confirmPassword = this.confirmPassword.current.value;

      if (newPassword !== confirmPassword) {
        return showErrorNotification(
          'Nytt lösenord och bekräfta att lösenordet inte matchar',
          'Lösenordet stämmer inte',
        );
      }

      this.setState({ btnLoginLoaderShow: true, errorShow: false, errorMessage: '' });

      if (this.state.changePassword) {
        await this.handleChangePassword();
      } else {
        await this.handleForgotPassword();
      }
      this.resetFields();
    } catch (e) {
      console.log(e, 'error in app-change-password');
      this.setState({ btnLoginLoaderShow: false, errorShow: true, errorMessage: e.response.data.error });
    }
  }

  handleForgotPassword = async () => {
    const verificationKey = _.get(this, 'props.match.params.verificationcode');
    const confirmPassword = this.confirmPassword.current.value;
    const response = await ServiceAuth.changePassword(confirmPassword, verificationKey);
    this.handleResponse(response);
    this.props.history.push('/');
  }

  handleChangePassword = async () => {
    const oldPassword = this.oldPassword.current.value;
    const confirmPassword = this.confirmPassword.current.value;
    const response = await ServiceAuth.changeUserPassword(oldPassword, confirmPassword);

    this.handleResponse(response);
  };

  handleResponse = (response) => {
    if (response.responseStatus == 'error') {
      return showErrorNotification(response.message);
    }
    if (response.responseStatus === "success") {
      return showSuccessNotification(response.message);
    }
  }

  resetFields = () => {
    this.setState({ btnLoginLoaderShow: false, errorShow: false, errorMessage: '' });
    this.newPassword.current.value = '';
    this.confirmPassword.current.value = '';
    if(this.state.changePassword) this.oldPassword.current.value = '';
  };

  render() {
    const { intl } = this.props;
    const { btnLoginLoaderShow, errorShow, errorMessage, email, changePassword } = this.state;
    return (
      <div className="app-login forgot-pass-form-wrapper">
        {!changePassword && <Logo size="194px" type="logoWithTitle" />}

        <div className="input-wrapper" style={{ marginTop: '0' }}>
          {!changePassword && <div class="title">Återställ ditt lösenord</div>}
          {changePassword == true ? (
            <React.Fragment>
              <AppLabel class="ar-label v2" headline="E-postadress">
                <input
                  className="ar-text-field disabled mb-c-6"
                  placeholder={intl.formatMessage({
                    id: 'global.gammalLösenord',
                    defaultMessage: 'E-postadress',
                  })}
                  disabled={true}
                  name="E-postadress"
                  defaultValue={this.props.email}
                />
              </AppLabel>
              <AppLabel class="ar-label v2" headline="Gammalt lösenord">
                <input
                  className="ar-text-field"
                  placeholder={intl.formatMessage({
                    id: 'global.gammalLösenord',
                    defaultMessage: 'Gammalt lösenord',
                  })}
                  autoCapitalize="off"
                  autoComplete="new-password"
                  name="oldPassword"
                  type="password"
                  ref={this.oldPassword}
                />
              </AppLabel>
            </React.Fragment>
          ) : (
            <AppLabel class="ar-label" headline="E-postadress">
              <input
                className="ar-text-field disabled mb-c-6"
                placeholder={intl.formatMessage({
                  id: 'global.email',
                  defaultMessage: 'E-post',
                })}
                disabled={true}
                name="E-postadress"
                defaultValue={email}
              />
            </AppLabel>
          )}
          <AppLabel class={`ar-label ${changePassword && 'v2'}`} headline="Nytt lösenord">
            <input
              className="ar-text-field"
              placeholder={intl.formatMessage({
                id: 'global.nyttLösenord',
                defaultMessage: 'Nytt lösenord',
              })}
              autoCapitalize="off"
              name="newPassword"
              type="password"
              ref={this.newPassword}
            />
          </AppLabel>
          <AppLabel class={`ar-label ${changePassword && 'v2'}`} headline="Bekräfta lösenord">
            <input
              className="ar-text-field"
              placeholder={intl.formatMessage({
                id: 'global.bekräftaLösenord',
                defaultMessage: 'Bekräfta lösenord',
              })}
              autoCapitalize="off"
              name="confirmPassword"
              type="password"
              ref={this.confirmPassword}
            />
          </AppLabel>

          <div className="btn-login-wrapper">
            <BtButton
              class="ar-button primary"
              loaderShow={btnLoginLoaderShow}
              onClick={this.btnSubmitPasswordClickCallback}
            >
              <FormattedMessage id="login.lämna" defaultMessage="Skicka" />
            </BtButton>
          </div>
        </div>
        <div className={`error ${errorShow ? 'show' : 'hide'}`}>
          <p>
            <FormattedMessage
              id="login.felaktivtAnvändarnamnEllerLösenord"
              defaultMessage={errorMessage || "Alla lösenordsfält krävs"}
            />
          </p>
        </div>
      </div>
    );
  }
}
//injectHistory(AppLogin);
export default withRouter(injectIntl(AppChangePassword));
