import axios from "axios";
import { ServiceProjects } from "./service-projects";
import appState from "../state/AppStateContainer";
import { post, IHttpResponse, get } from "./../utils/bt-http";
import { BehaviorSubject } from "rxjs";
import { ServiceUser } from "./service-user";
import { apiUrl } from "../global/app";
import BaseService from "./baseService";
import  moment  from 'moment';
// import azurestorage from "azure-storage";

// class BlobStorage {
//     constructor() {
//     }

//     blobServiceWithAzure() {
//         const blobService = azurestorage.createBlobService(
//           process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME,
//             process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_ACCESS_KEY
//         );
//         return blobService;
//     }
// }

// class Singleton {

//     constructor() {
//         if (!Singleton.instance) {
//             Singleton.instance = new BlobStorage();
//         }
//     }

//     getInstance() {
//         return Singleton.instance;
//     }

// }


export class ServiceAuthController extends BaseService {
  data$ = new BehaviorSubject({});
  storage = {};
  isLoggedIn = false;
  // blobStorageConnect = new Singleton().getInstance();
  /**
   * login
   * @description login User
   * @static
   * @param {String} username - username
   * @param {String} password - Goal Description
   * @returns {Object.<User>}
   * @memberOf Service
   */
  async login(email, password) {
    const response = await axios.post("/api/auth/login", {
      email,
      password
    });
  // if(response.data.userData.imgId)  {
  //  const avatarUrl = this.getAvatarUrl(response.data.userData.imgId);
  //  response.data.userData.avatarUrl = avatarUrl;

  // }
    return response.data;
  }
  async forgotPassword(email) {
    const response = await axios.post("/api/auth/forgot", {
      email
    });
    return response.data;
  }

  async changePassword(password, verificationKey) {
    const response = await axios.post("/api/auth/forgot/password", {
      password,
      verificationKey
    });
    return response.data;
  }

  async changeUserPassword(oldPassword, confirmPassword) {
    const response = await axios.post("/api/auth/change/password", {
      oldPassword,
      confirmPassword
    }, { headers: { "x-hvd-token": appState.getHvdToken() } });
    return response.data;
  }

  // async login(username = '', password = '') {
  //   return await post(
  //     apiUrl + "/login",
  //     {
  //       username: username,
  //       password: password
  //     }
  //   ).then((data) => {
  //     this.handleSuccess(data);
  //     this.save();
  //   })
  // }

  async loginToken(token) {
    return await post(apiUrl + "/login", {
      token: token
    })
      .then(data => {
        this.handleSuccess(data);
      })
      .catch(() => {});
  }

  async verifyAndGetSupplierToken(encryptedLink, orgSlug) {
    const response = await axios.post('/api/auth/verifyAndGetSupplierToken', {
      encryptedLink: encryptedLink,
      orgSlug: orgSlug,
    });
    if (response?.data) appState.setSupplierToken(response.data);

    if (response?.data?.supplierConversationData) {
      // Redirect /:orgSlug/conversation/:conversationSecretLink to 
      // /:orgSlug/quotation/info/:projectQuotationSecretLink/communication
      const { projectQuotationLink } = response.data.supplierConversationData;
      if (projectQuotationLink) {
        window.location = `/${orgSlug}/quotation/info/${projectQuotationLink}/communication`;
      }
    }
    return response.data;
  }

  async loginStorage() {
    var user = this.get();
    if (user) {
      await this.loginToken(user.id);
    }
    return user;
  }

  handleSuccess(data) {
    ServiceUser.add(data.parsedBody.user);
    ServiceProjects.replace(data.parsedBody.projects);
    this.save();
  }

  save() {
    localStorage.setItem("user", JSON.stringify(ServiceUser.storage));
    this.isLoggedIn = true;
  }

  get() {
    var user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      return user;
    }
  }

  add(data) {
    this.storage = data;
    this.data$.next(this.storage);
  }
  // getAvatarUrl(imgId){
   
  //   var startDate = moment().format();
  //   var expiryDate = moment().add(30,'days').format();
  //   // expiryDate.setMinutes(startDate.getDate() + 30);
  //   // startDate.setMinutes(startDate.getDate() - 30);
  //   const containerName = "accurator";
  //   const blobService = this.blobStorageConnect.blobServiceWithAzure();
  //   var sharedAccessPolicy = {
  //     AccessPolicy: {
  //       Permissions: azurestorage.BlobUtilities.SharedAccessPermissions.READ,
  //       Start: startDate,
  //       Expiry: expiryDate,
  //     },
  //   };
  //   var token = blobService.generateSharedAccessSignature(
  //     containerName,
  //   imgId,
  //     sharedAccessPolicy,
  //   );

  //   var sasUrl = blobService.getUrl(containerName, imgId, token);
  // return sasUrl;
  // }
  async logout() {
    const wasMicrosoft = appState.state.token.method === "microsoft";
    const loginHint = appState.state.token.loginHint;
    await appState.clearToken();
    localStorage.removeItem("user");
    localStorage.removeItem("theme");
    // ServiceUser.clear();
    //ServiceProjects.clear();
    this.isLoggedIn = false;
    if (wasMicrosoft) {
      window.location = this.getMicrosoftLogoutLink(loginHint);
    }
  }

  getMicrosoftLogoutLink(loginHint) {
    const origin = encodeURIComponent(new URL(window.location).origin + "/");
    const logoutHintPart = loginHint ? ("&logout_hint=" + encodeURIComponent(loginHint)) : "";
    return "https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=" + origin + logoutHintPart;
  }

  async getMicrosoftSignInUrl() {
    const response = await axios.get("/api/sso/redirect-url");
    return response.data;
  }

  async finishMicrosoftSignIn(code) {
    const response = await axios.get("/api/sso/finish", {params: { code }});
    return response.data;
  }

  getUserFromVerificationCode = async (verificationKey) => {
    const response = await axios.post("/api/auth/user", { verificationKey });
    return response.data;
  }
  
  authenticateUserForSignup = async (invitationCode, orgSlug) => {
    const response = await axios.post("/api/auth/user", { invitationCode }, 
    {
      params: { orgSlug }
    });
    return response.data;
  }

  async finishHvdSignIn(token, product, country) {
    const response = await axios.get("/api/hvd/finish", {params: { token, product, country }});
    return response.data;
  }
}

export const ServiceAuth = new ServiceAuthController();
